'use strict';

const el = {

    clock: {
        hands:   document.getElementsByClassName('hand'),
        face:    document.getElementById('face'),
        hour:    document.getElementById('hour'),
        minute:  document.getElementById('minute'),
        second:  document.getElementById('second')
    },

    options: {
        left:    document.getElementById('options-left'),
        right:   document.getElementById('options-right'),
        toggle:  document.getElementById('options-toggle'),
        open:    document.getElementById('options-open'),
        close:   document.getElementById('options-close'),
        bodyBg:  document.getElementById('option-bodybg'),
        faceBg:  document.getElementById('option-facebg'),
        hue:     document.getElementById('option-hue'),
        motion:  document.getElementById('option-motion'),
        alarm:   document.getElementById('option-alarm')
    }

};
