/* globals el */
'use strict';

// Polyfill performance using less accurate date object if necessary
window.performance = window.performance || {};
performance.now = performance.now || function () {
    return new Date().getTime();
};


const sc = {

    now:             null,
    hue:             0,
    handIndex:       0,
    alarmDur:        0,

    angle: {
        hour:        0,
        minute:      0,
        second:      0
    },

    time: {
        hour:        null,
        minute:      null,
        second:      null
    },

    sounds: {
        alarm:       null,
        tick:        null
    },

    options: {
        bodyBg:      'none',
        faceBg:      'none',
        isHue:       false,
        motion:      'smooth',

        alarm: {
            hour:    null,
            minute:  null
        }
    },

    // MP3 is only preferred because the files are smaller
    audioFormat: (() => {
        const testAudio = new Audio();
        let format;
        if (testAudio.canPlayType('audio/mpeg')) {
            format = 'mp3';
        } else if (testAudio.canPlayType('audio/ogg')) {
            format = 'ogg';
        }
        return format;
    })(),

    loadSound(sound) {
        //console.log(`loadSound(${sound})`);
        if (sc.audioFormat) {
            return new Audio(`sounds/${sound}.${sc.audioFormat}`);
        }
    },

    getQuery(key) {
        //console.log(`getQuery(${key})`);
        const query = window.location.search.substring(1),
              pairs = query.split('&');
        let value;
        for (let i = 0; i < pairs.length; i++) {
            let pair = pairs[i].split('=');
            if (pair[0] === key) {
                value = pair[1];
                break;
            }
        }
        return value;
    },

    rotate(elem, angle = sc.angle[elem] || 0) {
        //console.log(`rotate(${elem}, ${angle})`);
        typeof elem === 'string' && (elem = el.clock[elem]);
        elem.style.transform = `rotate(${angle}deg)`;
    },

    getRndBg(exclude, aspectRatio) {
        console.log(`getRndBg(${exclude}, ${aspectRatio})`);
        const backgrounds = ['none', 'lines', 'spiral', 'swirl', 'tent'];

        // The 'star' and 'inv-star' backgrounds are only suitable for bodyBg with squarish aspect ratios
        const isSquarish = (typeof aspectRatio === 'number' && (aspectRatio > 0.9 && aspectRatio < 1.2));
        isSquarish && backgrounds.push('star', 'inv-star');

        if (typeof exclude === 'string') {
            const excludeIndex = backgrounds.indexOf(exclude);
            excludeIndex > -1 && backgrounds.splice(excludeIndex, 1);
        }
        const bgIndex = Math.floor(Math.random() * backgrounds.length);
        return backgrounds[bgIndex];
    },

    getAspectRatio() {
        console.log('getAspectRatio()');
        return window.innerWidth / window.innerHeight;
    },

    setBodybg(bodyBg = sc.getRndBg(sc.options.bodyBg, sc.getAspectRatio())) {
        console.log(`setBodybg(${bodyBg})`);
        if (bodyBg !== sc.options.bodyBg) {
            document.body.className = 'bg ' + bodyBg;
            el.options.bodyBg.className = 'bg ' + bodyBg;
            sc.options.bodyBg = bodyBg;
        }
    },

    setFacebg(faceBg = sc.getRndBg(sc.options.faceBg, 1)) {
        console.log(`setFacebg(${faceBg})`);
        if (faceBg !== sc.options.faceBg) {
            el.clock.face.className = 'bg ' + faceBg;
            el.options.faceBg.className = 'bg ' + faceBg;
            sc.options.faceBg = faceBg;
        }
    },

    setHue(isRotate = !sc.options.isHue) {
        console.log(`setHue(${isRotate})`);
        sc.options.isHue = isRotate;
        el.options.hue.classList[isRotate ? 'add' : 'remove']('on', 'color-grad');
    },

    setMotion(motion = sc.options.motion === 'tick' ? 'smooth' : 'tick') {
        console.log(`setMotion(${motion})`);
        const isSmooth = (motion === 'smooth');
        [...el.clock.hands].forEach(hand => {
            hand.classList[isSmooth ? 'add' : 'remove']('smooth');
        });
        sc.options.motion = motion;
        el.options.motion.classList[isSmooth ? 'add' : 'remove']('smooth');
    },

    resetAlarm() {
        console.log('resetAlarm()');
        sc.options.alarm.hour = null;
        sc.options.alarm.minute = null;
        el.options.alarm.classList.remove('on', 'bell');
    },

    validateTime(time) {
        console.log(`validateTime(${time})`);
        const isStr = typeof time === 'string' && time.length === 4,
               hour = isStr ? parseInt(time.substring (0, 2)) : -1,
             minute = isStr ? parseInt(time.substring(2, 4)) : -1,
            isValid = (hour >= 0 && hour <= 23) && (minute >= 0 && minute <= 59);
        return isValid ? time : false;
    },

    getAlarmTime(currentAlarmTime = `${sc.options.alarm.hour || '00'}:${sc.options.alarm.minute || '00'}`) {
        console.log(`getAlarmTime(${currentAlarmTime})`);
        const time = prompt('Enter alarm time in 24 hour format (hh:mm)', currentAlarmTime),
           milTime = typeof time === 'string' ? time.replace(/:/g, '').substring(0, 4) : null;
        return milTime;
    },

    setAlarmTime(time) {
        console.log(`setAlarmTime(${time})`);
        if (time) {
            sc.options.alarm.hour   = parseInt(time.substring(0, 2));
            sc.options.alarm.minute = parseInt(time.substring(2, 4));
        }
        return time;
    },

    padNum(num) {
        //console.log(`padNum(${num})`);
        return `0${num}`.substr(-2);
    },

    getDemoTime() {
        console.log('getDemoTime()');
        const date = new Date(),
              hour = sc.padNum(date.getHours());
           let min = sc.padNum(date.getMinutes() + 1);
        min === '60' && (min = '00');
        return hour + min;
    },

    setAlarm(alarmTime, isInit) {
        console.log(`setAlarm(${alarmTime})`);
        if (alarmTime === 'demo') {
            sc.setAlarmTime(sc.getDemoTime());
            el.options.alarm.classList.add('on', 'bell');
        } else if (alarmTime && sc.validateTime(alarmTime)) {
            sc.setAlarmTime(alarmTime);
            el.options.alarm.classList.add('on', 'bell');
        } else if (!isInit && sc.setAlarmTime(sc.validateTime(sc.getAlarmTime()))) {
            el.options.alarm.classList.add('on', 'bell');
            sc.setTime();  // Because prompt dialog pauses animation
            sc.now = performance.now();
        } else {
            sc.resetAlarm();
        }
    },

    setUrl() {
        console.log('setUrl()');
        let url = `https://clock.seanf.net/?bg=${sc.options.bodyBg}&face=${sc.options.faceBg}`;
        if (sc.options.isHue) {
            url += '&hue=' + sc.options.isHue;
        }
        if (sc.options.motion === 'smooth') {
            url += '&motion=' + sc.options.motion;
        }
        if (sc.options.alarm.hour && sc.options.alarm.minute) {
            url += '&alarm=' + sc.padNum(sc.options.alarm.hour) + sc.padNum(sc.options.alarm.minute);
        }
        window.history.replaceState({}, '', url);
    },

    setTime(date = new Date()) {
        console.log(`setTime(${date})`);
        sc.time.hour = date.getHours();
        sc.time.minute = date.getMinutes();
        sc.time.second = date.getSeconds();

        // 30 degrees between adjacent hour points
        // 6 degrees between adjacent minute and second points
        // 0.5 degrees hour hand movement per minute
        // Subtract 45 degrees to point correct corner of square element
        sc.angle.hour = sc.time.hour * 30 + (sc.time.minute / 2) - 45;
        sc.angle.minute = sc.time.minute * 6 - 45;
        sc.angle.second = sc.time.second * 6;

        sc.rotate('hour');
        sc.rotate('minute');
        sc.rotate('second');
    },

    doAlarm() {
        console.log('doAlarm()');
        sc.sounds.alarm && sc.sounds.alarm.paused && sc.sounds.alarm.play();
        document.body.classList.toggle('alarm-alt');
        setTimeout(() => {
            document.body.classList.toggle('alarm-alt');
        }, 495);
    },

    startAlarm() {
        console.log('startAlarm()');
        sc.alarmDur = 1;
        document.body.classList.add('alarm');
        document.body.addEventListener('click', sc.stopAlarm);
    },

    stopAlarm() {
        console.log('stopAlarm()');
        sc.alarmDur = 0;
        document.body.classList.remove('alarm', 'alarm-alt');
        document.body.removeEventListener('click', sc.stopAlarm);
    },

    checkAlarm() {
        console.log('checkAlarm()');
        if (sc.time.hour === sc.options.alarm.hour &&
            sc.time.minute === sc.options.alarm.minute) {
            sc.startAlarm();
        }
    },

    tick() {
        //console.log('tick()');
        if (++sc.time.second === 60) {
            sc.time.second = 0;
            sc.options.motion === 'tick' && (sc.angle.second = 0);
            sc.angle.minute += 6;
            if (++sc.time.minute === 60) {
                sc.time.minute = 0;
                sc.angle.minute = -45;
                ++sc.time.hour === 12 && (sc.time.hour = 0);
            }
            sc.angle.hour += 0.5;
            sc.angle.hour === 360 && (sc.angle.hour = 0);
            sc.rotate('minute');
            sc.rotate('hour');
            sc.checkAlarm();
        } else {
            sc.angle.second += 6;
        }
        if (sc.alarmDur) {
            if (++sc.alarmDur < 600) {
                sc.doAlarm();
            } else {
                sc.stopAlarm();
            }
        } else if (sc.options.motion === 'tick') {
            sc.sounds.tick && sc.sounds.tick.play();
        }
        sc.rotate('second');
    },

    rotateHue() {
        //console.log('rotateHue()');
        --sc.handIndex === -1 && (sc.handIndex = 2);
        sc.hue += 0.02;
        sc.hue === 360 && (sc.hue = 0);
        const hue = (sc.handIndex === 0) ? sc.hue * 2 : sc.hue,
           filter = `hue-rotate(${hue}deg)`;
        el.clock.hands[sc.handIndex].style.WebkitFilter = filter;
        el.clock.hands[sc.handIndex].style.filter = filter;
    },

    doFrame() {
        //console.log('doFrame()');
        const now = performance.now(),
             diff = now - sc.now;
        if (diff > 1000) {
            sc.now = now + (diff - 1000);
            sc.tick();
        }
        sc.options.isHue && sc.rotateHue();
        requestAnimationFrame(sc.doFrame);
    }
};


// Make clock available offline via a service worker
if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('sw.js').then(reg => {
        console.info('Service worker registered with scope: ', reg.scope);
    }).catch(error => {
        console.error('Service worker not registered: ', error);
    });
}

// Handle click on options-toggle button
el.options.toggle.addEventListener('click', () => {
    console.info('Event: click on options-toggle');
    el.options.left.classList.toggle('show');
    el.options.right.classList.toggle('show');
    el.options.toggle.classList.toggle('show');
});

// Handle clicks on all buttons within options-right
el.options.right.addEventListener('click', event => {
    const id = event.target.id;
    console.info('Event: click on ' + id);
    if (typeof id === 'string' && id.indexOf('option-') === 0) {
        const setOption = 'set' + id.charAt(7).toUpperCase() + id.substring(8, id.length);
        if (typeof sc[setOption] === 'function') {
            sc[setOption]();
            sc.setUrl();
        }
    }
});

// Update the clock’s time when returning to the page
document.addEventListener('visibilitychange', () => {
    console.info('Event: document visibilitychange');
    if (document.hidden) {
        sc.alarmDur && sc.stopAlarm();
    }else {
        sc.setTime();
    }
});

// Set body height on window resize to combat vh issue
window.addEventListener('resize', () => {
    console.info('Event: window resize');
    document.body.style.height = window.innerHeight + 'px';
});

// Initialize and start animation
document.body.style.height = window.innerHeight + 'px';
sc.sounds.tick = sc.loadSound('tick');
sc.sounds.alarm = sc.loadSound('alarm');
sc.setBodybg(sc.getQuery('bg'));
sc.setFacebg(sc.getQuery('face'));
sc.setMotion(sc.getQuery('motion'));
sc.setAlarm(sc.getQuery('alarm'), true);
sc.setHue(sc.getQuery('hue') || false);
sc.setUrl();
sc.setTime();
requestAnimationFrame(sc.doFrame);
